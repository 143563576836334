import Layout from 'components/public-layout';
import { useEffect } from 'react';

const CookiesAdvice = () => {
  useEffect(() => {
    // Anything in here is fired on component mount.
    var cookieBotScript = document.createElement('script');
    cookieBotScript.src = 'https://consent.cookiebot.com/ce2ba34a-a408-4256-8e98-ee3d3415ac65/cd.js';
    cookieBotScript.id = 'CookieDeclaration';
    //document.body.appendChild(cookieBotScript);

    let parentNode = document.getElementById('cookiesContent').parentNode;
    parentNode.append(cookieBotScript);

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, []);
  return (
    <Layout cssClass="contact">
      <section className="main">
        <div className="main-content">
          <div className="container"></div>
        </div>
      </section>
      <section>
        <div className="landing-sample white-bg container">
          <div className="content-wrapper">
            <div className="sample-header clearfix"></div>
            <h1 className="text-center">Cookie Policy</h1>
            <div id="cookiesContent" />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CookiesAdvice;
