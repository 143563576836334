import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import ManagementService from 'shared/management-service';

//libs
import { replaceComa, formatPrice } from 'libs/methods';
import { isEmpty } from 'libs/lodashAlt';

//Component
import Card from 'components/card';
import Pagination from 'components/pagination';
import Loading from 'components/loading';

import IconData from 'components/icons/data';
import IconPersonal from 'components/icons/personal';
import IconEmail from 'components/icons/email';
import IconSummary from 'components/icons/resum';
import IconWarning from 'components/icons/warning';
import IconPending from 'components/icons/pending';
import IconPaid from 'components/icons/paid';
import IconPaidTwo from 'components/icons/paidTwo';

// style
import './index.scss';

const Billing = () => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();

  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState('pending');
  const [pager, setPager] = useState({
    pages: null,
    page: '1',
    items: '10',
  });

  const getData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });

    ManagementService.billing()
      .then((responseData) => {
        setRawData(responseData.message);
        if (responseData.status === 'Accepted') {
          let pageNumber = 0;
          let pager = {
            pages: null,
            page: '1',
            items: '10',
          };
          let filter = 'pending';

          const indexOfLast = pager.page * pager.items;
          const indexOfFirst = indexOfLast - pager.items;
          const billings = responseData.message.total_billing.filter((billings) => billings.status === filter);
          const currentBillings = billings.slice(indexOfFirst, indexOfLast);

          for (let i = 1; i <= Math.ceil(billings.length / pager.items); i++) pageNumber++;

          setPager({
            ...pager,
            pages: pageNumber,
          });

          setData({
            pending: responseData.message.pending,
            paid: responseData.message.paid,
            total: currentBillings,
          });
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    /* getBilling(auth.token, dispatch).then((response) => {
      setRawData(response.data.message);
      if (response.data.status === 'Accepted') {
        let pageNumber = 0;
        let pager = {
          pages: null,
          page: '1',
          items: '10',
        };
        let filter = 'pending';

        const indexOfLast = pager.page * pager.items;
        const indexOfFirst = indexOfLast - pager.items;
        const billings = response.data.message.total_billing.filter((billings) => billings.status === filter);
        const currentBillings = billings.slice(indexOfFirst, indexOfLast);

        for (let i = 1; i <= Math.ceil(billings.length / pager.items); i++) pageNumber++;

        setPager({
          ...pager,
          pages: pageNumber,
        });

        setData({
          pending: response.data.message.pending,
          paid: response.data.message.paid,
          total: currentBillings,
        });
      }
    }); */
  }, [dispatch, setData]);

  useEffect(() => getData(), [getData]);

  const loadData = (_filter, _pager) => {
    let pageNumber = 0;
    const indexOfLast = _pager.page * _pager.items;
    const indexOfFirst = indexOfLast - _pager.items;
    const billings = rawData.total_billing.filter((billings) => billings.status === _filter);
    const currentBillings = billings.slice(indexOfFirst, indexOfLast);

    setData({
      pending: rawData.pending,
      paid: rawData.paid,
      total: currentBillings,
    });
    for (let i = 1; i <= Math.ceil(billings.length / _pager.items); i++) pageNumber++;

    setPager({
      ..._pager,
      pages: pageNumber,
    });
  };

  const filtersBilling = async (filter) => {
    await setFilter(filter);
    loadData(filter, { pages: null, page: '1', items: pager.items });
  };

  const total = (type, billing) => {
    if (type === 'videochat') {
      const primerGroup = parseFloat(replaceComa(billing.e_adsle)) + parseFloat(replaceComa(billing.e_int));
      const segunGroup = parseFloat(replaceComa(billing.e_micropagos)) + parseFloat(replaceComa(billing.e_sms));
      const tercerGroup = parseFloat(replaceComa(billing.e_visap)) + parseFloat(replaceComa(billing.e_actricesvisa));
      const quartGroup = parseFloat(replaceComa(billing.e_actricessms)) + parseFloat(replaceComa(billing.e_movil));
      return formatPrice(`${primerGroup + segunGroup + tercerGroup + quartGroup}`);
    }
    if (type === 'total') {
      const primerGroup = parseFloat(replaceComa(billing.e_adsle)) + parseFloat(replaceComa(billing.e_int));
      const segunGroup = parseFloat(replaceComa(billing.e_referidos)) + parseFloat(replaceComa(billing.e_sms));
      const tercerGroup = parseFloat(replaceComa(billing.extra)) + parseFloat(replaceComa(billing.e_micropagos));
      const quartGroup = parseFloat(replaceComa(billing.e_visap)) + parseFloat(replaceComa(billing.e_actricesvisa));
      const quinGroup =
        parseFloat(replaceComa(billing.e_actricessms)) +
        parseFloat(replaceComa(billing.e_movil)) +
        parseFloat(replaceComa(billing.e_olecams));
      const total = primerGroup + segunGroup + tercerGroup + quartGroup + quinGroup;
      return formatPrice(`${total}`);
    }
    return null;
  };

  // PAGINATE METHODS
  const paginate = (e) => {
    setPager({
      ...pager,
      page: e.target.value,
    });
    loadData(filter, { ...pager, page: e.target.value });
  };

  const handleChangeNumberOfColum = (e) => {
    setPager({
      ...pager,
      items: e.target.value,
      page: 1,
    });
    loadData(filter, { ...pager, items: e.target.value, page: 1 });
  };

  const nextPage = async (page) => {
    page++;
    if (page <= pager.pages) {
      await setPager({
        ...pager,
        page: page,
      });
      loadData(filter, { ...pager, page: page });
    }
  };

  const prevPage = (page) => {
    page--;
    if (page > 0) {
      setPager({
        ...pager,
        page: page,
      });
      loadData(filter, { ...pager, page: page });
    }
  };
  // END PAGINATE METHODS

  if (loading) return <Loading />;
  return (
    <>
      {!isEmpty(data) && (
        <div className="facturacion-content">
          <div className="facturacion-cont-top">
            <div className="text-content">
              <p>
                <IconWarning color="#4B5056" />
                <FormattedMessage id="administration-billing-descrip" />
              </p>
              <p>
                <IconWarning color="#4B5056" />
                <FormattedMessage id="global-invoice-request-minimum-amount" />
              </p>
            </div>
          </div>
          <div className="data-content">
            <Card icon={<IconPersonal />} title={<FormattedMessage id="administration-billing-data-title" />}>
              <div className="data">
                <p>Capital Nexus Investment Ltd.</p>
                <p>15603311</p>
                <p>
                  <strong>Domicilio social:</strong> 11 Pheasant Walk, High Legh, Knutsford, Cheshire, England, WA16 6LU.
                </p>
              </div>
            </Card>
            <Card icon={<IconEmail />} title={<FormattedMessage id="administration-billing-email-title" />}>
              <div className="email">
                <p>
                  <FormattedMessage id="administration-billing-email-text" />
                </p>
                <p>facturacion@promocionesweb.com</p>
              </div>
            </Card>
          </div>
          <Card icon={<IconData />} title={<FormattedMessage id="administration-billing-resum-title" />}>
            <div className="resum-text-content">
              <p>
                <FormattedMessage id="administration-billing-resum-descrip" />
              </p>
              <p className="sub">
                <b>
                  <FormattedMessage id="administration-billing-resum-pending" />
                </b>{' '}
                <FormattedMessage id="administration-billing-resum-pending-text" />
              </p>
              <p className="sub">
                <b>
                  <FormattedMessage id="administration-billing-resum-paid" />
                </b>{' '}
                <FormattedMessage id="administration-billing-resum-paid-text" />
              </p>
              <p className="sub">
                <b>
                  <FormattedMessage id="administration-billing-resum-receiced" />
                </b>{' '}
                <FormattedMessage id="administration-billing-resum-receiced-text" />
              </p>
            </div>
          </Card>
          <Card icon={<IconSummary />} title={<FormattedMessage id="administration-billing-resum-resum" />} table>
            <div className="table-content">
              <table className="table">
                <thead>
                  <tr>
                    <th className="mayus" scope="col">
                      <FormattedMessage id="administration-billing-resum-pending-name" />
                    </th>
                    <th className="mayus" scope="col">
                      <FormattedMessage id="administration-billing-resum-paid-name" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{formatPrice(data.pending)}</td>
                    <td>{formatPrice(data.paid)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
          <div className="billing-resum-content">
            <Card header={false}>
              <div className="pendend-pay-content">
                <span onClick={(e) => filtersBilling('pending')} className={classNames({ active: filter === 'pending' })}>
                  <IconPending color="#4b5056" /> <FormattedMessage id="administration-billing-resum-pending-name" />
                </span>
                <span onClick={(e) => filtersBilling('paid')} className={classNames({ active: filter === 'paid' })}>
                  <IconPaidTwo /> <FormattedMessage id="administration-billing-resum-paid-name" />
                </span>
              </div>
              <div className="cards row">
                {data.total.map((billing, index) => {
                  return (
                    <div key={index} className="col-6 card-content">
                      <Card
                        icon={<IconSummary />}
                        title={
                          <FormattedMessage id="administration-billing-period" values={{ period: billing.period, date: billing.date }} />
                        }
                        table>
                        <div className="table-content">
                          <table className="table">
                            <thead>
                              <tr>
                                <th className={billing.status} scope="col" colSpan="3">
                                  {billing.status === 'pending' && (
                                    <>
                                      <IconPending color="#0B648D" />
                                      <FormattedMessage id="administration-billing-resum-pending-name" />
                                    </>
                                  )}
                                  {billing.status === 'paid' && (
                                    <>
                                      <IconPaid />
                                      <FormattedMessage id="administration-billing-resum-paid-name" />
                                    </>
                                  )}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="title">
                                  <FormattedMessage id="administration-billing-import-olecams" />
                                </td>
                                <td>{formatPrice(billing.e_olecams)}</td>
                              </tr>
                              <tr>
                                <td className="title">
                                  <FormattedMessage id="administration-billing-import-videochat" />
                                </td>
                                <td>{total('videochat', billing)}</td>
                              </tr>
                              <tr>
                                <td className="title">
                                  <FormattedMessage id="administration-billing-import-referred" />
                                </td>
                                <td>{formatPrice(billing.e_referidos)}</td>
                              </tr>
                              <tr>
                                <td className="title">
                                  <FormattedMessage id="administration-billing-import-extra" />
                                </td>
                                <td>{formatPrice(billing.extra)}</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <th scope="col" colSpan="3">
                                  TOTAL {total('total', billing)}
                                </th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </div>
              <div>
                <Pagination
                  handleChangeNumberOfColum={handleChangeNumberOfColum}
                  pager={pager}
                  paginate={paginate}
                  prevPage={prevPage}
                  nextPage={nextPage}
                />
              </div>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default Billing;
