import { Link } from 'react-router-dom';

import Layout from 'components/public-layout';
import { getLang } from 'libs/cookies';

const PrivacyPolicy = () => {
  if (getLang() === 'es') return <PrivacyPolicyEs />;
  if (getLang() === 'en') return <PrivacyPolicyEn />;
};

const PrivacyPolicyEs = () => {
  return (
    <Layout cssClass="contact">
      <section className="main">
        <div className="main-content">
          <div className="container"></div>
        </div>
      </section>
      <section>
        <div className="landing-sample white-bg container">
          <div className="content-wrapper">
            <div className="sample-header clearfix"></div>

            <div>
              <h1 className="text-center">Política de privacidad</h1>
              <div className="">
                <h2>1. Responsable</h2>
                <p>El Responsable del tratamiento de los datos recabados por medio de este Sitio Web es:</p>
                <ul>
                  <li>Capital Nexus Investment Ltd. (en adelante, "LA EMPRESA")</li>{' '}
                  <li>11 Pheasant Walk, High Legh, Knutsford, Cheshire, England, WA16 6LU.</li>
                  <li>
                    <Link to={{ pathname: 'mailto:dpo@capitalnexus.co.uk' }} role="button" target="_blank" rel="noopener noreferrer">
                      dpo@capitalnexus.co.uk
                    </Link>
                  </li>
                </ul>
                <h2>2. Finalidades</h2>
                <p>Los datos personales del usuario de este Sitio Web se tratarán para las siguientes finalidades:</p>
                <ul>
                  <li>
                    Posibilitar el mantenimiento, desarrollo y gestión de la relación negocial formalizada con los Usuarios que solicitan
                    recibir los productos y/o servicios de LA EMPRESA. Esta finalidad incluye el hecho de registrarse en el Sitio Web, así
                    como los inicios de sesión posteriores. Los datos tratados con esta finalidad se conservarán mientras se mantenga dicha
                    relación y, una vez finalizada ésta, durante los plazos de conservación y de prescripción de responsabilidades
                    legalmente previstos. La base jurídica del tratamiento es la ejecución de un contrato en el que el interesado es parte.
                  </li>{' '}
                  <li>
                    Atender solicitudes de información y/o consultas efectuadas por el Usuario. Los datos tratados con esta finalidad se
                    conservarán hasta haber dado respuesta a la solicitud de información y/o consulta y, tras ello, durante los plazos de
                    conservación y de prescripción de responsabilidades legalmente previstos. La base jurídica del tratamiento es: a) el
                    consentimiento del Usuario en caso de que utilice el formulario de contacto existente en este Sitio Web; o b) el interés
                    legítimo de LA EMPRESA en dar una respuesta al Usuario en caso de que éste no utilice dicho formulario (envío de e-mails
                    espontáneos, llamadas telefónicas, envío de solicitudes escritas por correo postal).
                  </li>{' '}
                  <li>
                    Mantener informado al Usuario, incluso por medios electrónicos, acerca de los productos, servicios y novedades de LA
                    EMPRESA. Los datos tratados con esta finalidad se conservarán hasta la revocación del consentimiento dado para la
                    recepción de dichas comunicaciones y, tras ello, durante los plazos de conservación y de prescripción de
                    responsabilidades legalmente previstos. La base jurídica del tratamiento es el consentimiento del Usuario manifestado a
                    través de las vías puestas a su disposición en este Sitio Web.
                  </li>
                </ul>
                <h2>3. Destinatarios</h2>{' '}
                <p>
                  LA EMPRESA podrá comunicar los datos a Administraciones Públicas para el cumplimiento de obligaciones legales y a Fuerzas
                  y Cuerpos de Seguridad del Estado y/o los Juzgados y Tribunales que los requieran en el marco de una investigación,
                  instrucción o procedimiento. También podrá comunicar los datos a las siguientes categorías de encargados: Proveedores de
                  comunicaciones electrónicas, ofimática, hosting, housing, mantenimiento informático, gestoría, contabilidad, auditoría,
                  asesoría y representación legal. Algunos de dichos encargados pueden estar ubicados fuera del Espacio Económico Europeo,
                  en cuyo caso LA EMPRESA habrá adoptado previamente las garantías adecuadas en materia de protección de datos.
                </p>
                <h2>4. Derechos</h2>
                <p>
                  Los interesados pueden ejercer sus derechos de acceso, rectificación, supresión, limitación del tratamiento, portabilidad
                  de los datos, oposición, derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado
                  -incluida la elaboración de perfiles- que produzca efectos jurídicos en él o le afecte significativamente de modo similar,
                  así como retirar en cualquier momento el consentimiento sin que ello afecte a la licitud del tratamiento previo a su
                  retirada, enviando su solicitud a LA EMPRESA (A/A DPD), 11 Pheasant Walk, High Legh, Knutsford, Cheshire, England, WA16
                  6LU.; o a la dirección de correo electrónico{' '}
                  <Link to={{ pathname: 'mailto:dpo@capitalnexus.co.uk' }} role="button" target="_blank" rel="noopener noreferrer">
                    dpo@capitalnexus.co.uk
                  </Link>
                  En todo caso, los interesados tienen derecho a presentar una reclamación ante la correspondiente autoridad de control si
                  lo estiman oportuno.
                </p>
                <p>
                  LA EMPRESA ha designado un Delegado de Protección de Datos con el que se puede contactar a través de las vías antes
                  mencionadas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

const PrivacyPolicyEn = () => {
  return (
    <Layout cssClass="contact">
      <section className="main">
        <div className="main-content">
          <div className="container"></div>
        </div>
      </section>
      <section>
        <div className="landing-sample white-bg container">
          <div className="content-wrapper">
            <div className="sample-header clearfix"></div>

            <div>
              <h1 className="text-center">Privacy Policy</h1>
              <div className="">
                <h2>1. Data Controller</h2>
                <p>The Data Controller of the information collected through this Website is:</p>
                <ul>
                  <li>Capital Nexus Investment Ltd. (hereinafter, "THE COMPANY")</li>{' '}
                  <li>11 Pheasant Walk, High Legh, Knutsford, Cheshire, England, WA16 6LU.</li>
                  <li>
                    <Link to={{ pathname: 'mailto:dpo@capitalnexus.co.uk' }} role="button" target="_blank" rel="noopener noreferrer">
                      dpo@capitalnexus.co.uk
                    </Link>
                  </li>
                </ul>
                <h2>2. Purposes</h2>
                <p>The personal data of the user of this Website will be processed for the following purposes:</p>
                <ul>
                  <li>
                    To enable the maintenance, development, and management of the business relationship formalized with Users requesting to
                    receive THE COMPANY's products and/or services. This purpose includes registering on the Website, as well as subsequent
                    logins. The data processed for this purpose will be kept as long as such relationship exists and, once it ends, for the
                    periods legally established for storage and liability limitation. The legal basis for the processing is the performance
                    of a contract to which the data subject is a party.
                  </li>{' '}
                  <li>
                    To handle requests for information and/or inquiries made by the User. The data processed for this purpose will be kept
                    until the request for information and/or inquiry is answered and, thereafter, for the periods legally established for
                    storage and liability limitation. The legal basis for the processing is: a) the User's consent in case they use the
                    contact form on this Website; or b) THE COMPANY's legitimate interest in responding to the User if they do not use the
                    form (spontaneous emails, phone calls, written requests via postal mail).
                  </li>{' '}
                  <li>
                    To keep the User informed, including via electronic means, about THE COMPANY's products, services, and news. The data
                    processed for this purpose will be kept until the consent given for receiving such communications is revoked and,
                    thereafter, for the periods legally established for storage and liability limitation. The legal basis for the processing
                    is the User's consent provided through the means available on this Website.
                  </li>
                </ul>
                <h2>3. Recipients</h2>{' '}
                <p>
                  THE COMPANY may disclose the data to Public Authorities to comply with legal obligations and to Law Enforcement Agencies
                  and/or Courts that require them in the framework of an investigation, instruction, or procedure. It may also disclose the
                  data to the following categories of processors: Providers of electronic communications, office software, hosting, housing,
                  IT maintenance, management, accounting, auditing, legal advice, and representation. Some of these processors may be
                  located outside the European Economic Area, in which case THE COMPANY will have previously adopted appropriate data
                  protection safeguards.
                </p>
                <h2>4. Rights</h2>
                <p>
                  Data subjects can exercise their rights of access, rectification, erasure, restriction of processing, data portability,
                  objection, the right not to be subject to automated decision-making - including profiling - that produces legal effects or
                  significantly affects them, as well as the right to withdraw consent at any time without affecting the lawfulness of the
                  processing based on consent before its withdrawal, by sending their request to THE COMPANY (A/A DPO), 11 Pheasant Walk,
                  High Legh, Knutsford, Cheshire, England, WA16 6LU.; or to the email address{' '}
                  <Link to={{ pathname: 'mailto:dpo@capitalnexus.co.uk' }} role="button" target="_blank" rel="noopener noreferrer">
                    dpo@capitalnexus.co.uk
                  </Link>
                  In any case, data subjects have the right to lodge a complaint with the competent supervisory authority if they deem it
                  appropriate.
                </p>
                <p>THE COMPANY has appointed a Data Protection Officer who can be contacted through the aforementioned channels.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
