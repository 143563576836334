import { Card, Form } from 'react-bootstrap';

const DemoIframe = ({ designForm, configForm, filters, set_result_url, id_edit_roomlist, languages_list, random, iframe_style_raw }) => {
  let age = '';
  let iframe_styles = {
    width: '',
    minWidth: '',
    height: designForm.iframe_height + 'px',
  };
  let width = '';

  let card_body_styles = {};

  if (designForm.iframe_size === 'adaptive') {
    iframe_styles = {
      width: '100%',
      minWidth: '70px',
      height: designForm.iframe_height + 'px',
    };
    card_body_styles = {
      width: '90%',
      height: parseInt(designForm.iframe_height),
    };
    width = '';
  } else {
    width = designForm.iframe_width;
    card_body_styles = {
      width: parseInt(designForm.iframe_width),
      height: parseInt(designForm.iframe_height),
    };
  }

  filters.age !== '' ? (age = filters.age.ini + '-' + filters.age.end) : (age = '');

  let lang = 'auto';
  if (languages_list && Array.isArray(languages_list)) {
    languages_list.forEach((element) => {
      if (element.checked) lang = element.value;
    });
  }

  const params = new URLSearchParams({
    campaign: configForm.campaign,
    domain: configForm.domain,
    afno: configForm.prefix_afno + configForm.afno,
    access_url: configForm.access_url,
    whitelabel_url: configForm.whitelabel_url,
    thumb_size: designForm.thumb_size,
    page_bg_color: designForm.page_bg_color,
    nick_color: designForm.nick_color,
    nick_bg_color: designForm.nick_bg_color,
    button_bg_color: designForm.button_bg_color,
    button_text_color: designForm.button_text_color,
    segment: filters.segment,
    category: filters.category,
    country: filters.country,
    age: age,
    chat: filters.chat,
    roomlist_limit: designForm.roomlist_limit,
    roomlist_limit_count: designForm.roomlist_limit_count,
    language: lang,
  });

  let url = 'https://recursos.promocionesweb.com/olecams/lists/?' + params.toString();

  return (
    <>
      <Card.Body className="m-auto p-0 iframe_body" style={card_body_styles}>
        <iframe
          title="roomlist_demo"
          scrolling="no"
          frameBorder="0"
          width={width}
          height={designForm.iframe_height}
          style={iframe_styles}
          key={random}
          src={url}></iframe>
      </Card.Body>
      {id_edit_roomlist !== '' && (
        <Form.Control
          as="textarea"
          disabled
          value={
            "<iframe scrolling='no' frameborder='0' style='" +
            iframe_style_raw +
            ' height: ' +
            designForm.iframe_height +
            'px;' +
            "' height='" +
            designForm.iframe_height +
            "' width='" +
            width +
            "' src='" +
            set_result_url +
            "'></iframe>"
          }
        />
      )}
    </>
  );
};

export default DemoIframe;
