import { Link } from 'react-router-dom';

import Layout from 'components/public-layout';
import { getLang } from 'libs/cookies';

const LegalAdvice = () => {
  if (getLang() === 'es') return <LegalAdviceEs />;
  if (getLang() === 'en') return <LegalAdviceEn />;
};

const LegalAdviceEs = () => {
  return (
    <Layout cssClass="contact">
      <section className="main">
        <div className="main-content">
          <div className="container"></div>
        </div>
      </section>
      <section>
        <div className="landing-sample white-bg container">
          <div className="content-wrapper">
            <div className="sample-header clearfix"></div>

            <div>
              <h1 className="text-center">AVISO LEGAL</h1>
              <div className="">
                <p>
                  Este Aviso Legal (en adelante, “el Aviso Legal”) rige el acceso y la utilización del sitio web accesible a través del
                  nombre de dominio{' '}
                  <Link to={{ pathname: 'https://oletraffic.com' }} role="button" target="_blank" rel="noopener noreferrer">
                    https://oletraffic.com
                  </Link>{' '}
                  y sus subdominios (en adelante, “el Sitio Web”). El simple acceso al Sitio Web atribuye a quien lo realiza la condición de
                  usuario del mismo (en adelante, “el Usuario”). En caso de no estar de acuerdo con este Aviso Legal, el Usuario debe
                  abandonar inmediatamente el Sitio Web sin utilizarlo.
                </p>
                <p>Por medio de la aceptación de este Aviso Legal el Usuario manifiesta:</p>
                <ul>
                  <li>a) Que ha leído, entiende y acepta lo aquí expuesto.</li>
                  <li>b) Que es mayor de edad.</li>
                </ul>
                <h2>1. Información general del Sitio Web</h2>
                <p>A continuación se ofrece la información general del Sitio Web:</p>
                <p>
                  <strong>Titular:</strong> Capital Nexus Investment Ltd. (en adelante, "LA EMPRESA").
                </p>
                <p>15603311</p>
                <p>
                  <strong>Domicilio social:</strong> 11 Pheasant Walk, High Legh, Knutsford, Cheshire, England, WA16 6LU.
                </p>{' '}
                <p>
                  <strong>Email:</strong>
                  <Link to={{ pathname: 'mailto:help@capitalnexus.co.uk' }} role="button" target="_blank" rel="noopener noreferrer">
                    help@capitalnexus.co.uk
                  </Link>
                </p>{' '}
                <h2>2. Acceso al Sitio Web</h2>{' '}
                <p>
                  El simple acceso al Sitio Web es gratuito salvo en lo relativo al coste de la conexión a través de la red de
                  telecomunicaciones suministrada por el proveedor de acceso contratado por el Usuario.
                </p>
                <h2>3. Menores de edad</h2>
                <p>
                  El contenido y los servicios que ofrece LA EMPRESA está exclusivamente destinado a mayores de edad y se prohíbe su uso y
                  consulta de menores de edad. Solicitamos expresamente a los padres o tutores, que tomen las medidas oportunas para evitar
                  que los menores de edad puedan darse de alta, utilizando programas de control parental o sistemas análogos, e informarnos
                  en caso de tener algún indicio de que el menor se ha dado de alta para tomar las medidas oportunas en cada caso. LA
                  EMPRESA se reserva el derecho de solicitar al Usuario la documentación que acredite su identidad en los casos que existan
                  dudas sobre la veracidad de sus datos, negándose la prestación de servicios si el Usuario no atiende dicha solicitud.
                </p>
                <p>
                  LA EMPRESA ha establecido los mecanismos de control razonables para evitar que los menores de edad utilicen los servicios
                  ofrecidos.
                </p>{' '}
                <h2>4. Normas de utilización del Sitio Web</h2>
                <p>
                  El Usuario se obliga a utilizar el Sitio Web y todo su contenido conforme a lo establecido en la ley, la moral, el orden
                  público y al presente Aviso Legal, no empleándolo para realizar actividades ilícitas o constitutivas de delito que atenten
                  contra los derechos de terceros y/o que infrinjan cualesquiera normas del ordenamiento jurídico aplicable.{' '}
                </p>
                <p>El Usuario se compromete a:</p>
                <ul>
                  <li>
                    I. No introducir o difundir contenidos o propaganda de carácter racista, xenófobo, de apología del terrorismo o que
                    atenten contra los derechos humanos.
                  </li>
                  <li>
                    II. No introducir o difundir en la red programas de datos (virus y software nocivo) susceptibles de provocar daños en
                    los sistemas informáticos del proveedor de acceso, sus proveedores o terceros Usuarios de la red Internet.
                  </li>
                  <li>
                    III. No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido que
                    atente contra los derechos fundamentales y las libertades públicas reconocidos constitucionalmente y en los tratados
                    internacionales.
                  </li>{' '}
                  <li>
                    IV. No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido que
                    constituya publicidad ilícita o desleal.
                  </li>{' '}
                  <li>
                    V. No transmitir publicidad no solicitada o autorizada, material publicitario, "correo basura", "cartas en cadena",
                    "estructuras piramidales", o cualquier otra forma de solicitud, excepto en aquellas áreas (tales como espacios
                    comerciales) que hayan sido exclusivamente concebidas para ello.
                  </li>{' '}
                  <li>
                    VI. No introducir o difundir cualquier información y contenidos falsos, ambiguos o inexactos de forma que induzca a
                    error a los receptores de la información.{' '}
                  </li>
                  <li>
                    VII. No suplantar a otros Usuarios utilizando sus claves de registro a los distintos servicios y/o contenidos del Sitio
                    Web.{' '}
                  </li>
                  <li>
                    VIII. No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido que
                    suponga una violación de los derechos de propiedad intelectual e industrial, patentes, marcas o copyright que
                    correspondan a los titulares del Sitio Web o a terceros.
                  </li>{' '}
                  <li>
                    IX. No difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido que
                    suponga una violación del secreto de las comunicaciones y la legislación de datos de carácter personal.{' '}
                  </li>
                  <li>
                    X. El Usuario se obliga a mantener indemne a LA EMPRESA ante cualquier posible reclamación, multa, pena o sanción que
                    pueda venir obligada a soportar como consecuencia del incumplimiento por parte del Usuario de cualquiera de las normas
                    de utilización antes indicadas, reservándose, además, LA EMPRESA el derecho a solicitar la indemnización por daños y
                    perjuicios que corresponda.
                  </li>
                </ul>{' '}
                <h2>5. Cargos Revertidos y Disputas</h2>
                <p>
                  Para los afiliados que operen bajo el modelo de Pago Por Venta (PPS), la Empresa se reserva el derecho de deducir
                  cualquier monto relacionado con cargos revertidos, disputas o reclamaciones (incluyendo, entre otros, disputas de PayPal)
                  de la siguiente factura del afiliado. La deducción será equivalente al monto de PPS inicialmente pagado al afiliado por
                  la(s) venta(s) disputada(s). Esta cláusula no está limitada en el tiempo y se aplica a cualquier disputa que surja después
                  del pago. Los afiliados aceptan estos términos como parte de su participación en el programa.
                </p>
                <p></p>
                <h2>6. Contenidos y servicios enlazados a través del Sitio Web</h2>
                <p>
                  El Sitio Web puede contener dispositivos técnicos de enlace, directorios e incluso instrumentos de búsqueda que permitan
                  al Usuario acceder a otras páginas y portales de Internet (en adelante, “Sitios Enlazados”). En estos casos, LA EMPRESA
                  sólo será responsable de los contenidos y servicios suministrados en los Sitios Enlazados en la medida en que tenga
                  conocimiento efectivo de su ilicitud y no haya desactivado el enlace a los mismos con la diligencia debida. En el supuesto
                  de que el Usuario considere que existe un Sitio Enlazado con contenidos ilícitos o inadecuados, puede comunicarlo a LA
                  EMPRESA.
                </p>{' '}
                <p>
                  En ningún caso la existencia de Sitios Enlazados debe presuponer la formalización de acuerdos entre LA EMPRESA y los
                  responsables o titulares de los mismos, así como tampoco la recomendación o promoción de los Sitios Enlazados y/o sus
                  contenidos por parte de LA EMPRESA. A menos que se indique expresamente lo contrario en el Sitio Web, LA EMPRESA no conoce
                  los contenidos y servicios de los Sitios Enlazados y, por tanto, no se hace responsable por los daños y perjuicios que
                  éstos puedan producir al Usuario o a cualquier tercero.
                </p>{' '}
                <h2>7. Propiedad intelectual e industrial </h2>
                <p>
                  Todos los contenidos del Sitio Web entendiendo por éstos, a título meramente enunciativo pero no exhaustivo, los textos,
                  fotografías, gráficos, imágenes, iconos, tecnología, software, links y demás contenidos audiovisuales o sonoros, así como
                  su diseño gráfico y códigos fuente, son propiedad intelectual de LA EMPRESA o de terceros licenciadores, sin que puedan
                  entenderse cedidos al Usuario ninguno de los derechos de explotación reconocidos por la normativa vigente en materia de
                  propiedad intelectual sobre los mismos.
                </p>{' '}
                <p>
                  Las marcas, nombres comerciales y demás signos distintivos publicados en el Sitio Web son titularidad de LA EMPRESA o de
                  terceros cedentes y/o licenciadores, sin que pueda entenderse cedido al Usuario ningún derecho sobre los mismos.
                </p>{' '}
                <h2>8. Contraseña </h2>
                <p>
                  El Usuario es responsable en todo momento de la custodia de su contraseña para acceder a su cuenta, asumiendo en
                  consecuencia cualesquiera daños y perjuicios que pudieran derivarse de su uso indebido, así como de la cesión, revelación
                  o extravío de la misma. A estos efectos, el acceso a áreas restringidas y/o el uso de los servicios y contenidos del Sitio
                  Web realizado bajo su contraseña se reputarán realizados por el Usuario.{' '}
                </p>
                <p>
                  LA EMPRESA se reserva el derecho a anular discrecionalmente el acceso de algún usuario cuando concurran circunstancias de
                  las que se presuman utilizaciones ilícitas.
                </p>{' '}
                <h2>9. Reserva</h2>{' '}
                <p>
                  LA EMPRESA se reserva la facultad en todo momento, sin necesidad de previo aviso, de efectuar modificaciones y
                  actualizaciones de la información contenida en su Web o en su configuración y presentación. Asimismo, se reserva el
                  derecho a suspender temporalmente, y sin que sea necesario previo aviso, la accesibilidad a la Web por razones de eventual
                  necesidad y para efectuar operaciones de mantenimiento, reparación o mejora.
                </p>{' '}
                <p>
                  LA EMPRESA se reserva el derecho a incluir o retirar total o parcialmente de la Web, cualquier información a su exclusiva
                  discreción.
                </p>{' '}
                <h2>10. Quejas, reclamaciones y solicitudes de información </h2>
                <p>
                  El Usuario puede dirigir sus quejas, reclamaciones y/o solicitudes de información a LA EMPRESA, utilizando para ello
                  cualquiera de las siguientes vías:
                </p>{' '}
                <ul>
                  <li>a) Enviando un escrito a 11 Pheasant Walk, High Legh, Knutsford, Cheshire, England, WA16 6LU.</li>{' '}
                  <li>
                    b) Enviando un correo electrónico a la dirección{' '}
                    <Link to={{ pathname: 'mailto:help@capitalnexus.co.uk' }} role="button" target="_blank" rel="noopener noreferrer">
                      help@capitalnexus.co.uk
                    </Link>
                    .
                  </li>{' '}
                </ul>
                <h2>11. Nulidad e ineficacia de las cláusulas</h2>
                <p>
                  Si cualquier cláusula incluida en este Aviso Legal fuese declarada total o parcialmente nula o ineficaz, tal nulidad o
                  ineficacia tan sólo afectará a dicha disposición o a la parte de la misma que resulte nula o ineficaz, subsistiendo este
                  Aviso Legal en todo lo demás y considerándose tal disposición total o parcialmente por no incluida.
                </p>
                <h2>12. Legislación aplicable y jurisdicción</h2>{' '}
                <p>
                  Las partes acuerdan que los tribunales de Inglaterra y Gales tendrán jurisdicción exclusiva para resolver cualquier
                  disputa o reclamación que surja de este acuerdo o su objeto o formación (incluidas las disputas y reclamaciones no
                  contractuales) o en relación con ellos. Las partes acuerdan que los tribunales de Inglaterra y Gales tendrán jurisdicción
                  exclusiva para resolver cualquier disputa o reclamación que surja de este acuerdo o su objeto o formación (incluidas las
                  disputas y reclamaciones no contractuales) o en relación con ellos.
                </p>
                <h2>13. Modificación de los Términos</h2>{' '}
                <p>
                  LA EMPRESA se reserva el derecho de modificar o actualizar este Aviso Legal, la Política de Privacidad o los Términos de
                  Servicio en cualquier momento. Los usuarios deben revisar regularmente estos documentos para asegurarse de estar al tanto
                  de cualquier cambio. El uso continuo del sitio web después de que se hayan realizado dichas modificaciones constituye la
                  aceptación de los términos actualizados.
                </p>
                <h2>14. Cumplimiento de la Ley de Protección de Datos y el RGPD</h2>
                <p>
                  De acuerdo con el Reglamento General de Protección de Datos (RGPD), si se procesan datos personales en este sitio web, LA
                  EMPRESA garantiza la protección de la privacidad de los usuarios. Los datos personales solo serán recopilados, procesados
                  y almacenados conforme a las leyes aplicables, y los usuarios serán informados sobre cómo se manejan sus datos. Se
                  respetarán los derechos de los usuarios bajo el RGPD, incluyendo el derecho a acceder, rectificar y eliminar sus datos
                  personales.
                </p>
                <p>
                  Además, este sitio web puede utilizar cookies para fines de seguimiento, análisis y publicidad. Al usar este sitio web,
                  los usuarios aceptan el uso de cookies. Para más información sobre cómo se utilizan las cookies y cómo gestionarlas,
                  consulte nuestra detallada Política de Cookies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

const LegalAdviceEn = () => {
  return (
    <Layout cssClass="contact">
      <section className="main">
        <div className="main-content">
          <div className="container"></div>
        </div>
      </section>
      <section>
        <div className="landing-sample white-bg container">
          <div className="content-wrapper">
            <div className="sample-header clearfix"></div>

            <div>
              <h1 className="text-center">LEGAL ADVICE</h1>
              <div className="">
                <p>
                  This Legal Notice (hereinafter referred to as "the Legal Notice") governs access to and use of the website accessible
                  through the domain name https://oletraffic.com{' '}
                  <Link to={{ pathname: 'https://oletraffic.com' }} role="button" target="_blank" rel="noopener noreferrer">
                    https://oletraffic.com
                  </Link>{' '}
                  and its subdomains (hereinafter referred to as "the Website"). By simply accessing the Website, the user is granted the
                  status of a user of the Website (hereinafter referred to as "the User"). If the User does not agree with this Legal
                  Notice, they must immediately leave the Website and not use it.
                </p>
                <p>By accepting this Legal Notice, the User declares:</p>
                <ul>
                  <li>a) That they have read, understood, and accept the contents of this document.</li>
                  <li>b) That they are of legal age.</li>
                </ul>
                <h2>1. General Information about the Website</h2>
                <p>Below is the general information about the Website:</p>
                <p>
                  <strong>Owner:</strong> Capital Nexus Investment Ltd. (hereinafter referred to as "THE COMPANY").
                </p>
                <p>
                  <strong>Tax ID:</strong> 15603311
                </p>
                <p>
                  <strong>Registered Office:</strong> 11 Pheasant Walk, High Legh, Knutsford, Cheshire, England, WA16 6LU.
                </p>{' '}
                <p>
                  <strong>Email:</strong>
                  <Link to={{ pathname: 'mailto:help@capitalnexus.co.uk' }} role="button" target="_blank" rel="noopener noreferrer">
                    help@capitalnexus.co.uk
                  </Link>
                </p>{' '}
                <h2>2. Access to the Website</h2>{' '}
                <p>
                  Access to the Website is free, except for the cost of connecting via the telecommunications network provided by the access
                  provider contracted by the User.
                </p>
                <h2>3. Minors</h2>
                <p>
                  The content and services provided by THE COMPANY are exclusively for adults and the use of the Website is prohibited for
                  minors. We expressly request that parents or guardians take appropriate measures to prevent minors from registering, using
                  parental control programs or similar systems, and notify us if they suspect a minor has registered so we can take the
                  necessary actions. THE COMPANY reserves the right to request documentation verifying the identity of the User if there are
                  doubts regarding the accuracy of their data, refusing service if the User does not comply with this request.
                </p>
                <p>THE COMPANY has established reasonable control mechanisms to prevent minors from using the services offered.</p>{' '}
                <h2>4. Website Usage Rules</h2>
                <p>
                  The User agrees to use the Website and all its content in accordance with applicable laws, morals, public order, and this
                  Legal Notice, and not to engage in any illegal activities or actions that may infringe the rights of third parties or
                  violate any applicable laws.{' '}
                </p>
                <p>The User agrees to:</p>
                <ul>
                  <li>
                    I. Not introduce or spread content or propaganda that is racist, xenophobic, supportive of terrorism, or offensive to
                    human rights.
                  </li>
                  <li>
                    II. Not introduce or spread data programs (viruses and harmful software) that could damage the computer systems of the
                    access provider, its suppliers, or third-party Internet users.
                  </li>
                  <li>
                    III. Not spread, transmit, or make available any type of information, element, or content that violates fundamental
                    rights and freedoms recognized by the Constitution and international treaties.
                  </li>{' '}
                  <li>
                    IV. Not spread, transmit, or make available any type of information, element, or content constituting illegal or unfair
                    advertising.
                  </li>{' '}
                  <li>
                    V. Not transmit unsolicited or unauthorized advertising, promotional material, "spam", "chain letters", "pyramid
                    schemes", or any other form of solicitation, except in those areas (such as commercial spaces) that are exclusively
                    designed for this purpose.
                  </li>{' '}
                  <li>
                    VI. Not introduce or spread any false, ambiguous, or inaccurate information that may mislead recipients of the
                    information.{' '}
                  </li>
                  <li>VII. Not impersonate other Users by using their registration details for the Website’s services and/or content. </li>
                  <li>
                    VIII. Not spread, transmit, or make available any type of information, element, or content that infringes intellectual
                    and industrial property rights, patents, trademarks, or copyrights belonging to the Website owners or third parties.
                  </li>{' '}
                  <li>
                    IX. Not spread, transmit, or make available any type of information, element, or content that violates the
                    confidentiality of communications and personal data laws.{' '}
                  </li>
                  <li>
                    X. The User agrees to indemnify THE COMPANY against any potential claims, fines, penalties, or sanctions that may arise
                    as a result of the User's breach of any of the above rules, and THE COMPANY reserves the right to seek compensation for
                    any damages.
                  </li>
                </ul>{' '}
                <h2>5. Chargebacks and Disputes</h2>
                <p>
                  For affiliates operating under the Pay-Per-Sale (PPS) model, the Company reserves the right to deduct any amounts
                  associated with chargebacks, disputes, or claims (including but not limited to PayPal disputes) from the affiliate's
                  subsequent invoice. The deduction will correspond to the PPS amount initially paid to the affiliate for the disputed
                  sale(s). This clause is not limited by time and applies to any disputes arising after the payout. Affiliates agree to
                  these terms as part of their participation in the program.
                </p>
                <h2>6. Linked Content and Services</h2>
                <p>
                  The Website may contain technical link devices, directories, and even search tools that allow the User to access other
                  websites and portals on the Internet (hereinafter, "Linked Sites"). In such cases, THE COMPANY will only be responsible
                  for the contents and services provided in the Linked Sites to the extent that it has actual knowledge of their
                  unlawfulness and has not deactivated the link with due diligence. If the User believes that a Linked Site contains illicit
                  or inappropriate content, they may notify THE COMPANY.
                </p>{' '}
                <p>
                  The existence of Linked Sites should not be interpreted as an agreement between THE COMPANY and the owners or operators of
                  those sites, nor does it imply any recommendation or promotion of the Linked Sites and/or their contents by THE COMPANY.
                  Unless explicitly stated otherwise, THE COMPANY does not know the content and services of the Linked Sites, and therefore
                  does not assume responsibility for any damages that may be caused to the User or third parties.
                </p>{' '}
                <h2>7. Intellectual and Industrial Property </h2>
                <p>
                  All content on the Website, including but not limited to texts, photographs, graphics, images, icons, technology,
                  software, links, and other audiovisual or sound content, as well as its graphic design and source code, are the
                  intellectual property of THE COMPANY or third-party licensors, and no exploitation rights recognized by current
                  intellectual property laws are transferred to the User.
                </p>{' '}
                <p>
                  Trademarks, trade names, and other distinguishing signs published on the Website are owned by THE COMPANY or third-party
                  licensors, and no rights to them are transferred to the User.
                </p>{' '}
                <h2>8. Password </h2>
                <p>
                  The User is fully responsible for maintaining the confidentiality of their password to access their account, and
                  consequently, assumes all damages that may arise from its misuse, as well as any transfer, disclosure, or loss of the
                  password. Access to restricted areas and/or use of the Website’s services and content made under their password will be
                  considered as being performed by the User.{' '}
                </p>
                <p>THE COMPANY reserves the right to cancel any user's access at its discretion if unlawful use is suspected.</p>{' '}
                <h2>9. Reservation</h2>{' '}
                <p>
                  THE COMPANY reserves the right to make changes and updates to the information on the Website, as well as its configuration
                  and presentation, at any time and without prior notice. It also reserves the right to temporarily suspend, without prior
                  notice, access to the Website for maintenance, repair, or improvement operations.
                </p>{' '}
                <p>THE COMPANY may include or remove information from the Website, in whole or in part, at its sole discretion.</p>{' '}
                <h2>10. Complaints, Claims, and Information Requests </h2>
                <p>
                  The User can direct their complaints, claims, and/or information requests to THE COMPANY using any of the following
                  methods:
                </p>{' '}
                <ul>
                  <li>a) Sending a written notice to 11 Pheasant Walk, High Legh, Knutsford, Cheshire, England, WA16 6LU.</li>{' '}
                  <li>
                    b) Sending an email to{' '}
                    <Link to={{ pathname: 'mailto:help@capitalnexus.co.uk' }} role="button" target="_blank" rel="noopener noreferrer">
                      help@capitalnexus.co.uk
                    </Link>
                    .
                  </li>{' '}
                </ul>
                <h2>11. Nullity and Ineffectiveness of Provisions</h2>
                <p>
                  If any clause in this Legal Notice is declared totally or partially null or ineffective, such nullity or ineffectiveness
                  will only affect that provision or part of it, and the remainder of the Legal Notice will continue to apply as intended.
                </p>
                <h2>12. Governing Law and Jurisdiction</h2>{' '}
                <p>
                  The parties agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim
                  arising out of or in connection with this agreement or its subject matter or formation (including non-contractual disputes
                  and claims). The parties agree that the courts of England and Wales shall have exclusive jurisdiction to settle any
                  dispute or claim arising out of or in connection with this agreement or its subject matter or formation (including
                  non-contractual disputes and claims).
                </p>
                <h2>13. Modification of Terms</h2>{' '}
                <p>
                  THE COMPANY reserves the right to modify or update this Legal Notice, Privacy Policy, or Terms of Service at any time.
                  Users should regularly review these documents to ensure they are aware of any changes. Continued use of the Website after
                  such changes have been made constitutes acceptance of the updated terms.
                </p>
                <h2>14. Data Privacy and GDPR Compliance</h2>{' '}
                <p>
                  In accordance with the General Data Protection Regulation (GDPR), if personal data is processed on this website, THE
                  COMPANY ensures the protection of user privacy. Personal data will only be collected, processed, and stored in accordance
                  with applicable laws, and users will be informed of how their data is handled. Users' rights under GDPR, including the
                  right to access, rectify, and delete personal data, will be respected.
                </p>
                <p>
                  Additionally, this website may use cookies for tracking, analytics, and advertising purposes. By using this website, users
                  consent to the use of cookies. For more information on how cookies are used and how to manage them, please refer to our
                  detailed Cookie Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default LegalAdvice;
