import Layout from 'components/public-layout';
import './index.scss';

const LegalRecord = () => {
  return (
    <Layout cssClass="legal-record">
      <section className="main">
        <div className="main-content">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1>18 U.S.C.2257 Record-Keeping Requirements Compliance Statement</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="values white-bg no_padding">
        <div className="container">
          <p>
            All models, actors, actresses and other persons that appear in any visual portrayal of actual sexually explicit conduct
            appearing or otherwise contained in this Website were over the age of eighteen years at the time the visual image was created.
          </p>
          <p>
            All other visual depictions displayed on this Website are exempt from the provision of 18 U.S.C. section 2257 and 28 C.F.R. 75
            because said they do not portray conduct as specifically listed in 18 U.S.C section 2256 (2) (A) through (D), but are merely
            depictions of non-sexually explicit nudity, or are depictions of simulated sexual conduct, or are otherwise exempt because the
            visual depictions were created prior to July 3, 1995.
          </p>
          <p>
            With respect to all visual depictions displayed on this web site, whether of actual sexually explicit conduct, simulated sexual
            conduct or otherwise, all persons were at least 18 years of age when said visual depictions were created.
          </p>
          <p>
            The owners and operators of this Web site are not the primary producers (as that term is defined in 18 U.S.C. Section 2257) of
            any of the visual content contained in the Web site.
          </p>
          <p>The images contained on this site were published, republished, reproduced or reissued after December 02 2011.</p>
          <p>All records required to be kept by federal law are available for inspection at:</p>

          <b>
            Capital Nexus Investment Ltd.
            <br />
            Custodian of Records
            <br />
            11 Pheasant Walk, High Legh, Knutsford, Cheshire, England, WA16 6LU.
            <br />
          </b>
        </div>
      </section>
    </Layout>
  );
};
export default LegalRecord;
